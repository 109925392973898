import { Injectable, OnDestroy } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import {
  CloseStandaloneTaskRequest,
  CloseStandaloneTaskRequestCloseReasonEnum,
  StandaloneTaskItem,
} from '@api-clients/crm-api-client';
import { Observable, Subject, timer } from 'rxjs';
import { delay, retryWhen, share, switchMap, takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../../../environments/environment';
import { apiResponsePipe } from '../../../../api-response.pipe';

const REFRESH_INTERVAL = 120000;

@Injectable({
  providedIn: 'root',
})
export class RecommendationsApiService implements OnDestroy {
  private stopPollingSubject = new Subject<void>();

  constructor(private http: HttpClient) {}

  getRecommendationsList(employeeId: number): Observable<StandaloneTaskItem[]> {
    return timer(1, REFRESH_INTERVAL).pipe(
      switchMap(() =>
        this.http
          .get(
            `${AppConfig.apiUrl}/task/standalone/list?employeeId=${employeeId}&taskType=thanks_for_recommend`,
          )
          .pipe(apiResponsePipe),
      ),
      retryWhen(errors => errors.pipe(delay(REFRESH_INTERVAL))),
      share(),
      takeUntil(this.stopPollingSubject),
    );
  }

  closeTask(taskId: number) {
    const closeStandaloneTaskRequest: CloseStandaloneTaskRequest = {
      taskId,
      place: 'HERMES',
      closeReason: CloseStandaloneTaskRequestCloseReasonEnum.Success,
    };
    return this.http
      .post(`${AppConfig.apiUrl}/task/standalone/close`, closeStandaloneTaskRequest)
      .pipe(apiResponsePipe);
  }

  ngOnDestroy() {
    this.stopPollingSubject.next();
  }
}
