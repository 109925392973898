import { Action } from '@ngrx/store';
import { StandaloneTaskItem } from '@api-clients/crm-api-client';

export enum RecommendationsActionTypes {
  LoadRecommendations = '[Recommendations] Load Recommendations',
  RecommendationsLoaded = '[Recommendations] Recommendations loaded',
}

export class LoadRecommendations implements Action {
  readonly type = RecommendationsActionTypes.LoadRecommendations;
}

export class RecommendationsLoaded implements Action {
  readonly type = RecommendationsActionTypes.RecommendationsLoaded;
  constructor(public payload: StandaloneTaskItem[]) {}
}

export type RecommendationsAction = LoadRecommendations | RecommendationsLoaded;
