import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RecommendationsState } from './recommendations.state';

const getRecommendationsState = createFeatureSelector<RecommendationsState>('recommendations');

const getLoaded = createSelector(getRecommendationsState, (state: RecommendationsState) => state.loaded);

const getRecommendations = createSelector(
  getRecommendationsState,
  getLoaded,
  (state: RecommendationsState, isLoaded) => {
    return isLoaded ? state.list : [];
  },
);

export const recommendationsQuery = {
  getLoaded,
  getRecommendationsState,
  getRecommendations,
};
