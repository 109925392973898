import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { RecommendationsState } from './recommendations.state';
import { recommendationsQuery } from './recommendations.selectors';
import { LoadRecommendations } from './recommendations.actions';

@Injectable()
export class RecommendationsFacade {
  constructor(private store: Store<RecommendationsState>) {}

  recommendationsList$ = this.store.select(recommendationsQuery.getRecommendations);

  loadRecommendations() {
    this.store.dispatch(new LoadRecommendations());
  }
}
